import {
    createSlice,
    createAsyncThunk
} from "@reduxjs/toolkit";
import * as st from "./types";
import { Backend } from "./backend";
import { Env } from "./consts";

const initialState: st.IScraperState = {
    init: true,
    is_sidebar_large: localStorage.getItem("is_sidebar_large") ?
        localStorage.getItem("is_sidebar_large") === "true" : true,
    env: "prod"
};

export const scraperUser = createAsyncThunk(
    "scraper/user",
    async () => {
        const response = await Backend.getUser();
        return response;
    }
);

export const scraperOrgBalances = createAsyncThunk(
    "scraper/org_balances",
    async () => {
        const response = await Backend.getUserBalances();
        return response;
    }
);

export const scraperSlice = createSlice({
    name: "scraper",
    initialState,
    reducers: {
        toggleSidebar: (state) => {
            state.is_sidebar_large = !state.is_sidebar_large;
            localStorage.setItem("is_sidebar_large", state.is_sidebar_large.toString());
        }
    },
    extraReducers: (builder) => {
        builder.addCase(scraperUser.fulfilled, (state, action: any) => {
            state.user = action.payload.user;
            state.memberships = action.payload.memberships;
            state.is_sso = action.payload.is_sso;
            state.onboarding_status = action.payload.onboarding_status;
            state.env = action.payload.env;
            state.init = false;
        });
        builder.addCase(scraperOrgBalances.fulfilled, (state, action: any) => {
            state.org_balances = action.payload;
        });
    }
});

export const { toggleSidebar } = scraperSlice.actions;

export const selectInit = (state: any) => state.scraper.init;
export const selectEnv = (state: any): Env => state.scraper.env;
export const selectIsSidebarLarge = (state: any): boolean => state.scraper.is_sidebar_large;
export const selectUser = (state: any): st.IUser => state.scraper.user;
export const selectMemberships = (state: any): st.IMembership[] => state.scraper.memberships;
export const selectIsSSO = (state: any): boolean => (state.scraper.is_sso === true);
export const selectOnboardingStatus = (state: any): st.IOnboardingStatus => state.scraper.onboarding_status;
export const selectOrgBalances = (state: any): st.IOrgBalance[] => state.scraper.org_balances;

export default scraperSlice.reducer;