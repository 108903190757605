import {
    Fragment,
    useEffect,
    useState
} from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import {
    ArrowLeftIcon,
    TableCellsIcon
} from "@heroicons/react/24/outline";

import {
    selectEnv,
    selectIsSidebarLarge
} from "../lib/scraper.slice";
import {
    EXCEL_MIME_TYPES
} from "../lib/consts";
import {
    IExcelArraySheet,
    LookupTableUpdateType
} from "../lib/types";
import {
    classNames,
    removeFileNameExtension,
    setDocumentTitle
} from "../lib/utils";
import { Backend } from "../lib/backend";

import {
    LoadingSpinner,
    LoadingSpinnerLimit
} from "../components/LoadingSpinner";
import { Button } from "../components/Button";
import { Dropdown } from "../components/Dropdown";
import { Sheet } from "../components/Sheets";

type LookupTableDropAreaProps = {
    setSheets: (filename: string, sheets: IExcelArraySheet[]) => void
};

export function LookupTableDropArea(props: LookupTableDropAreaProps) {
    const { setSheets } = props;

    const [is_drag_over, setIsDragOver] = useState<boolean>(false);
    const [is_parsing_file, setIsParsingFile] = useState<boolean>(false);
    const [error_message, setErrorMessage] = useState<string | undefined>(undefined);

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragOver(true);
    }

    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragOver(false);
    }

    const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragOver(false);

        setIsParsingFile(true);
        setErrorMessage(undefined);

        const file = e.dataTransfer.files[0];

        if (file && EXCEL_MIME_TYPES.includes(file.type)) {
            try {
                try {
                    const form_data = new FormData();
                    form_data.append("file", file);
                    const sheets = await Backend.getArrayFromExcel(form_data);
                    setSheets(removeFileNameExtension(file.name), sheets);
                    setIsParsingFile(false);
                } catch (err) {
                    setErrorMessage("Error uploading file. Please try again.");
                    setIsParsingFile(false);
                }
            } catch (err) {
                setErrorMessage("Error uploading file. Please try again.");
                setIsParsingFile(false);
            }
        } else {
            setErrorMessage("Invalid file type. Please upload a Microsoft Excel file.");
            setIsParsingFile(false);
        }
    }

    return <Fragment>
        <div
            className={classNames(
                "flex justify-center items-center w-full h-96 border-2 border-dashed border-gray-300 rounded-lg p-5",
                is_drag_over ? "bg-sky-100" : ""
            )}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}>

            <div className="text-center">
                <TableCellsIcon className="mx-auto h-12 w-12 text-gray-400" />
                <h3 className="mt-2 text-sm font-semibold text-gray-900">Drop file here</h3>
                <p className="mt-1 text-sm text-gray-500">You can drop any Microsoft Excel file.</p>
            </div>
        </div>
        {/* Overlay text area with loading indicator */}
        {is_parsing_file && <div className="absolute inset-0 bg-white opacity-95">
            <div className="h-full w-full max-w-5xl flex items-center justify-center">
                <LoadingSpinnerLimit text="Processing file..." />
            </div>
        </div>}
        {error_message !== undefined && <div className="space-y-12 sm:space-y-16">
            <div>
                <p className="mt-1 max-w-3xl text-sm leading-6 text-red-600">
                    Error: {error_message}
                </p>
            </div>
        </div>}
    </Fragment>;
}

export function UploadLookupTable() {
    const navigate = useNavigate();

    const is_sidebar_large = useSelector(selectIsSidebarLarge);
    const env = useSelector(selectEnv);

    const { lookup_table_uuid } = useParams<{ lookup_table_uuid: string | undefined }>();

    const [is_init, setIsInit] = useState<boolean>(false);
    const [name, setName] = useState<string>("");
    const [sheets, setSheets] = useState<IExcelArraySheet[] | undefined>(undefined);
    const [selected_sheet_idx, setSelectedSheetIdx] = useState<number>(-1);
    const [selected_sheet, setSelectedSheet] = useState<IExcelArraySheet | undefined>(undefined);
    const [updating_type, setUpdatingType] = useState<LookupTableUpdateType | undefined>(undefined);

    useEffect(() => {
        if (lookup_table_uuid !== undefined) {
            setIsInit(false);
            Backend.getLookupTable({ lookup_table_uuid }).then((lookup_table) => {
                setName(lookup_table?.name || "");
                setSheets(undefined);
                setSelectedSheetIdx(-1);
                setSelectedSheet(undefined);
                setUpdatingType(undefined);
                setIsInit(true);
            });
        }
    }, [lookup_table_uuid]);

    useEffect(() => {
        if (lookup_table_uuid !== undefined) {
            setDocumentTitle(`Upload Lookup Table Data - ${name}`, env);
        }
    }, [lookup_table_uuid, name, env]);

    useEffect(() => {
        if (sheets && selected_sheet_idx !== -1) {
            setSelectedSheet(sheets[selected_sheet_idx]);
        } else {
            setSelectedSheet(undefined);
        }
    }, [sheets, selected_sheet_idx]);

    const handleParse = (_filename: string, sheets: IExcelArraySheet[]) => {
        setSheets(sheets);
        setSelectedSheetIdx(0);
    };

    const handleUpload = (update_type: LookupTableUpdateType) => {
        if (lookup_table_uuid !== undefined && sheets !== undefined && selected_sheet !== undefined) {
            setUpdatingType(update_type);
            Backend.uploadLookupTableVersion(
                { lookup_table_uuid, sheet: selected_sheet, update_type }
            ).then(() => {
                navigate(`/lookup_table/${lookup_table_uuid}`);
            });
        }
    };

    const can_upload = selected_sheet !== undefined && updating_type === undefined;

    if (!is_init) {
        return <div className={classNames("hidden lg:fixed lg:right-0 lg:inset-y-0 lg:flex lg:flex-row", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <LoadingSpinner />
        </div>;
    }

    return <div className={classNames("lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
        <div className="h-20 w-full bg-white border-b border-b-gray-200">
            <div className="pl-4 pr-10 py-4 flex flex-row items-start max-w-5xl">
                <Button icon={ArrowLeftIcon} href={`/lookup_table/${lookup_table_uuid}`} />
                <div className="pl-4 flex flex-col justify-start gap-1">
                    <h2 className="text-xl font-semibold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight">
                        Upload Lookup Table Data
                    </h2>
                    <h2 className="text-sm text-gray-400 tracking-tight">
                        Lookup table: {name.length > 0 ? name : "<no name>"}
                    </h2>
                </div>
                <div className="grow"></div>
                <div className="pl-4 flex flex-col justify-end gap-1">
                    <div className="text-right">
                        <Button
                            text="Merge (incoming wins)"
                            disabled={!can_upload}
                            loading={updating_type === "merge_incoming_wins"}
                            onClick={() => handleUpload("merge_incoming_wins")} />
                        <Button
                            text="Merge (existing wins)"
                            disabled={!can_upload}
                            loading={updating_type === "merge_existing_wins"}
                            onClick={() => handleUpload("merge_existing_wins")} />
                        <Button
                            text="Append"
                            disabled={!can_upload}
                            loading={updating_type === "append"}
                            onClick={() => handleUpload("append")} />
                        <Button
                            text="Upload"
                            highlight={true}
                            disabled={!can_upload}
                            loading={updating_type === "replace"}
                            onClick={() => handleUpload("replace")} />
                    </div>
                    <div className="flex items-end">
                        {/* place for tooltip */}
                    </div>
                </div>
            </div>
        </div>

        <div className="flex flex-col w-full py-10">
            <div className="px-10 max-w-5xl">
                <div className="grid grid-cols-4 items-center w-full">
                    <div className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">Name</div>
                    <div className="col-span-3">
                        <input
                            type="text"
                            value={name}
                            readOnly={true}
                            className="border border-gray-300 rounded-md w-full px-3 py-2 text-gray-900"
                            onChange={(e) => setName(e.target.value)} />
                    </div>
                </div>
            </div>
            <div className="px-10 pt-10 pb-5 max-w-5xl">
                <div className="flex flex-row items-center w-full">
                    <div className="flex-1 flex flex-col">
                        <div className="text-sm font-medium leading-6 text-gray-900">
                            Update Uploaded Data
                        </div>
                        <div className="text-sm text-gray-500">
                            You can replace existing uploaded data with a new Excel file or append new data to the existing data.
                        </div>
                    </div>
                </div>
                {sheets === undefined && <div className="mt-3"><LookupTableDropArea setSheets={handleParse} /></div>}
                {sheets !== undefined && <div className="mt-3 grid grid-cols-4 items-center w-full">
                    <div className="text-sm text-gray-500">Select Sheet</div>
                    <div className="col-span-3">
                        <Dropdown
                            values={sheets.map((sheet) => sheet.name)}
                            ids={sheets.map((_, idx) => `${idx}`)}
                            selected={`${selected_sheet_idx}`}
                            onChange={(idx) => setSelectedSheetIdx(parseInt(idx, 10))} />
                    </div>
                </div>}
                {sheets !== undefined && selected_sheet && <div className="pb-5 w-full">
                    <div className="outer-div pt-5">
                        <Sheet data={selected_sheet.data} show_header={true} />
                    </div>
                </div>}
            </div>

        </div>
    </div>;
}
