import {
    Fragment,
    useEffect,
    useState
} from "react";
import {
    useDispatch,
    useSelector
} from "react-redux";
import { useNavigate } from "react-router-dom";

import { SlChemistry } from "react-icons/sl";
import { TbTablePlus } from "react-icons/tb";
import {
    ArrowTopRightOnSquareIcon,
    XMarkIcon
} from "@heroicons/react/24/solid";
import {
    DocumentTextIcon,
    FaceSmileIcon
} from "@heroicons/react/24/outline";

import {
    scraperUser,
    selectIsSidebarLarge,
    selectMemberships,
    selectOnboardingStatus,
    selectUser
} from "../lib/scraper.slice";
import { AppDispatch } from "../store";
import {
    classNames,
    getNextOnboardingStep
} from "../lib/utils";
import {
    IItemSlim
} from "../lib/types";
import {
    ONBOARDING_STEPS,
    ORG_TYPES,
    USER_ROLES
} from "../lib/consts";
import {
    ITemplateBase,
    OnboardingStep,
} from "../lib/backend/extractions.types.generated";

import { Backend, BackendObj } from "../lib/backend";

import { BigButton } from "../components/BigButton";
import { CopyTextbox } from "../components/CopyTextbox";
import { Download } from "./Download";
import { Button } from "../components/Button";
import { ItemList } from "./Items";
import { LoadingSpinnerLimit } from "../components/LoadingSpinner";
import { DidYouKnow } from "../components/DidYouKnow";

const TUTORIALS = [
    { idx: 1, title: "How to create a CSV file for Xero from a PDF statement", video: "https://youtu.be/BUpWUAOIHXM?si=AWrXrcKpSW8TARYU", image: "thumbnail_xeropdftocsv.jpg" },
    { idx: 2, title: "Extracting data from Annual Reports", video: "https://youtu.be/7gbNMY_HZP0?si=fYnRrV1CWjaCuCEV", image: "thumbnail_annualreports.jpg" },
    { idx: 3, title: "Convert Demand Forecast PDF to Excel, then copy data to an Excel spreadsheet on SharePoint", video: "https://youtu.be/Ct-ILRLmFRg?si=EGK9pBwrqAOBtsip", image: "thumbnail_demandforecastsharepoint.jpg" },
    { idx: 4, title: "Automatically process all backorders from email to excel on SharePoint using Power Automate", video: "https://youtu.be/OPRtrK0SYgo?si=NHTcBGuFMw7ho9uQ", image: "thumbnail_backordersfromemail.jpg" },
    { idx: 5, title: "VLookUp - How to extract data from an Excel Spreadsheet Given Customer Material ID number in PDF", video: "https://youtu.be/sctK6wKzOUE?si=j4nHw2k-LfGcfSr3", image: "thumbnail_vlookupcustomermaterialid.jpg" },
    { idx: 6, title: "How to process multiple contracts for audits and reviews", video: "https://youtu.be/TA-eKuvQYI4?si=qoZLvWl9M_Dvv2FV", image: "thumbnail_contractauditsandreviews.jpg" },
    { idx: 7, title: "How to get Customer Part Numbers from the Purchase order Item description", video: "https://youtu.be/1eamr8wa3lo?si=5lb7NzEqqmhj8-lp", image: "thumbnail_customerpartnumbers.jpg" },
];

type OpenConfirmationsProps = {
    max_items: number;
    confirm_items?: IItemSlim[];
};

function OpenConfirmations(props: OpenConfirmationsProps) {
    const navigate = useNavigate();

    const { max_items, confirm_items } = props;

    if (confirm_items === undefined) {
        return <div className={classNames("my-5 border", max_items > 4 ? "pt-44 min-h-[400px]" : "pt-24 min-h-[270px]")}>
            <LoadingSpinnerLimit />
        </div>;
    }

    if (confirm_items.length === 0) {
        return <div className={classNames("my-5 border", max_items > 4 ? "pt-44 min-h-[400px]" : "pt-24 min-h-[270px]")} >
            <div className="text-center">
                <FaceSmileIcon className="mx-auto h-12 w-12 text-gray-400" />
                <h3 className="mt-2 text-sm font-semibold text-gray-900">Yay, all nice and tidy!</h3>
                <p className="mt-1 text-sm text-gray-500">No extractions that require confirmation.</p>
            </div>
        </div>;
    }

    const max_items_shown = Math.min(max_items, confirm_items.length);
    const confirm_items_shown = confirm_items.slice(0, max_items_shown);

    return <Fragment>
        <div className={classNames("hidden 2xl:block my-5 border", max_items > 4 ? "min-h-[400px]" : "min-h-[270px]")} >
            <ItemList items={confirm_items_shown} onItemSelected={(item) => { navigate(`/confirm/${item.uuid}`) }} is_wide={true} />
        </div>
        <div className={classNames("2xl:hidden my-5 border", max_items > 4 ? "min-h-[400px]" : "min-h-[270px]")} >
            <ItemList items={confirm_items_shown} onItemSelected={(item) => { navigate(`/confirm/${item.uuid}`) }} is_wide={false} />
        </div>
    </Fragment>;
}

export function Home() {
    const dispatch = useDispatch<AppDispatch>();
    const user = useSelector(selectUser);
    const onboarding_status = useSelector(selectOnboardingStatus);
    const is_sidebar_large = useSelector(selectIsSidebarLarge);
    const memberships = useSelector(selectMemberships);

    const [templates, setTemplates] = useState<ITemplateBase[] | undefined>(undefined);
    const [is_loading, setIsLoading] = useState<boolean>(false);
    const [confirm_items, setConfirmItems] = useState<IItemSlim[] | undefined>(undefined);
    const [tutorial_items, setTutorialItems] = useState<any[]>([]);

    useEffect(() => {
        BackendObj.extractions.listTemplates({}).then(({ templates }) => {
            setTemplates(templates);
        });
        BackendObj.extractions.getOpenExtractConfirmations({}).then(({ confirm_items }) => {
            setConfirmItems(confirm_items);
        });
        // shuffle tutorials and pick 3
        const random_tutorial_items = [];
        random_tutorial_items.push(TUTORIALS[Math.floor(Math.random() * TUTORIALS.length)]);
        random_tutorial_items.push(TUTORIALS[Math.floor(Math.random() * TUTORIALS.length)]);
        random_tutorial_items.push(TUTORIALS[Math.floor(Math.random() * TUTORIALS.length)]);
        setTutorialItems(random_tutorial_items);
    }, []);

    useEffect(() => {
        dispatch(scraperUser());
    }, [dispatch]);

    const onClose = () => {
        setIsLoading(true);
        Backend.updateOnboardingStep({
            step: `${getNextOnboardingStep(onboarding_status.step as OnboardingStep)}`
        }).then(() => {
            dispatch(scraperUser());
            setIsLoading(false);
        });
    };

    // convert onboarding step to step 1 through 4
    const onboarding_step = onboarding_status.step === ONBOARDING_STEPS.context ? 1 :
        onboarding_status.step === ONBOARDING_STEPS.scrape ? 2 :
            onboarding_status.step === ONBOARDING_STEPS.email ? 3 :
                onboarding_status.step === ONBOARDING_STEPS.download ? 4 : 5;

    // trail account
    const is_free = user.role === USER_ROLES.free;
    const personal_org = memberships.find((membership) => membership.org.type === ORG_TYPES.personal);
    const subscribe_url = personal_org ? `/stripe-subscribe/${personal_org.org.uuid}` : "/";

    return <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
        <div className="mx-16 mt-16 mb-12">
            <h2 className="text-xl font-semibold leading-7 text-gray-600 sm:truncate sm:text-3xl sm:tracking-tight">
                Welcome, {user?.first_name}!
            </h2>

            <div className="pt-5 border-b-4 border-sky-600" />
        </div>

        {is_free && onboarding_step >= 5 && <div className="mx-10 mb-12 bg-orange-50 border rounded-lg">
            <div className="px-5 py-5 flex flex-row items-center">
                <p>This is a free trial account. For additional features, credits and support, please upgrade to our paid plans.</p>
                <div className="flex-grow" />
                <Button text="Upgrade" href={subscribe_url} />
            </div>
        </div>}

        {onboarding_step < 5 && <div className="mx-10 mb-12 bg-gray-50 border rounded-lg">
            <div className="px-5 pt-5">
                <div className="flex flex-row font-bold text-gray-700 items-center">
                    <p>Getting started</p>
                    <p className="ml-5 font-normal text-gray-400">{onboarding_step} / 4</p>
                    <div className="flex-grow" />
                    <Button icon={XMarkIcon} disabled={is_loading} onClick={onClose} />
                </div>
            </div>

            {onboarding_status.step === ONBOARDING_STEPS.context &&
                <div className="m-5 grid grid-cols-1 gap-4 items-center sm:grid-cols-2 text-gray-600">
                    <div>
                        <p className="mb-5">
                            Let's get started by creating a template. A template is a way to tell  what data you want to extract from a document.
                        </p>
                        <p className="mb-8">
                            Start by clicking the button below or watching a video.
                        </p>
                        <div className="mb-5">
                            <BigButton title="Upload your document" icon={DocumentTextIcon} href="/template/wizard" />
                        </div>
                        <div>
                            <BigButton title="Create a custom Template" icon={TbTablePlus} href="/template/new" />
                        </div>
                    </div>
                    <div className="px-10 lg:px-10 xl:px-20">
                        <div className="video-container">
                            <iframe
                                src="https://www.youtube-nocookie.com/embed/bW9adqL2_K0?controls=0&amp;rel=0&amp;autoplay=1"
                                title="How to create a template"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" />
                        </div>
                    </div>
                </div>}

            {onboarding_status.step === ONBOARDING_STEPS.scrape &&
                <div className="m-5 grid grid-cols-1 gap-4 items-center sm:grid-cols-2 text-gray-600">
                    <div>
                        <p className="mb-5">
                            Once you have created a template, you can start extracting data from text, PDF or Excel file.
                        </p>
                        <p className="mb-8">
                            You can do this by clicking the button below.
                        </p>
                        <BigButton title="2. New Extraction" icon={SlChemistry} href="/extraction/new" />
                    </div>
                    <div className="px-10 lg:px-10 xl:px-20">
                        <div className="video-container">
                            <iframe
                                src="https://www.youtube-nocookie.com/embed/q5kmv5hkr0o?controls=0&amp;rel=0&amp;autoplay=1"
                                title="How to make an extraction"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" />
                        </div>
                    </div>
                </div>}

            {onboarding_status.step === ONBOARDING_STEPS.email &&
                <div className="m-5 grid grid-cols-1 gap-4 items-center sm:grid-cols-1 text-gray-600">
                    <div>
                        <p className="mb-4">
                            You can extract data directly from your email just by forwarding it to us.
                        </p>
                        {templates && templates.length > 0 && <p className="my-5">
                            For example, to extract data from template {templates[0].name}, forward your email to:
                        </p>}
                        {templates && templates.length > 0 && <div className="max-w-xl mb-2">
                            <CopyTextbox text={templates[0].email_address} email_pretty_name={templates[0].name} is_email={true} />
                        </div>}
                    </div>
                    {/* <div className="px-10 lg:px-10 xl:px-20 mt-5">
                        <div className="video-container">
                            <iframe
                                src="https://www.youtube-nocookie.com/embed/q5kmv5hkr0o?rel=0&amp;controls=0"
                                title="How to create a template"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" />
                        </div>
                    </div> */}
                </div>}


            {onboarding_status.step === ONBOARDING_STEPS.download &&
                <div className="m-5 grid grid-cols-1 gap-4 items-center sm:grid-cols-1 text-gray-600">
                    <div>
                        <p className="mb-5">
                            You can download your extractions as Excel, CSV or JSON files.
                        </p>
                        <Download
                            cols_lg={templates ? Math.min(templates.length, 2) : 1}
                            max_items={2}
                            skip_empty_list={true}
                            onDownload={() => { dispatch(scraperUser()); }} />
                    </div>
                    {/* <div className="px-10 lg:px-10 xl:px-20 mt-5">
                        <div className="video-container">
                            <iframe
                                src="https://www.youtube-nocookie.com/embed/q5kmv5hkr0o?rel=0&amp;controls=0"
                                title="How to create a template"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" />
                        </div>
                    </div> */}
                </div>}
        </div>}

        <div className="grid grid-cols-1 md:grid-cols-2">
            <div className="2xl:hidden ml-10 mr-10 md:mr-5">
                <div className="mx-5 font-bold">Pending Confirmations</div>
                <OpenConfirmations max_items={3} confirm_items={confirm_items} />
            </div>
            <div className="hidden 2xl:block ml-10 mr-10 md:mr-5">
                <div className="mx-5 font-bold">Pending Confirmations</div>
                <OpenConfirmations max_items={6} confirm_items={confirm_items} />
            </div>
            <div className="ml-10 mr-10 md:ml-5">
                <div className="mx-5 font-bold">Did you know?</div>
                <DidYouKnow />
            </div>
        </div>

        <div className="mx-10 mb-10 mt-10 border bg-gray-50 rounded-lg">
            <div className="px-5 pt-5">
                <div className="mx-2 flex flex-row text-xl text-gray-700 ">
                    Tutorials
                    <a href="https://youtube.com/playlist?list=PL2L0wZwiAtyy9v9_vmNCSYedIIX_ldbGx&feature=shared">
                        <ArrowTopRightOnSquareIcon className="h-5 w-5 ml-2 text-gray-400 cursor-pointer" />
                    </a>
                </div>
            </div>

            <div className="px-5 pt-1 pb-5 grid grid-cols-1 gap-4 sm:grid-cols-3">
                {tutorial_items.map((tutorial, idx) => <div key={idx} className="w-full">
                    <p className="text-sm font-semibold py-4 h-16">{tutorial.title}</p>
                    <div className="video-container">
                        <a href={tutorial.video} target="_blank" rel="noreferrer">
                            <img src={`/tutorials/${tutorial.image}`} alt={tutorial.title} className="opacity-70 transition-transform transform hover:scale-105" />
                        </a>
                    </div>
                </div>)}
            </div>
        </div>
    </div >;
}
