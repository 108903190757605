import {
    Fragment,
    useEffect,
    useState
} from "react";
import { useSelector } from "react-redux";

import {
    useParams,
    useNavigate,
    Link
} from "react-router-dom";

import * as xlsx from "xlsx";
import { saveAs } from "file-saver";

import * as hi from "@heroicons/react/24/outline";
import * as hs from "@heroicons/react/20/solid";

import {
    classNames,
    getExcelColumnName,
    getLocalHour,
    isValidIpAddress,
    prettyDateTime,
    prettySmartDateTime,
    setDocumentTitle
} from "../lib/utils";
import * as t from "../lib/types";
import {
    ENDPOINT_TYPE,
    ORG_TYPES,
    USER_ROLES
} from "../lib/consts";
import {
    ExtractConfirmationStatus,
    IEndpointForwardEmailItem
} from "../lib/backend/extractions.types.generated";
import {
    selectEnv,
    selectIsSidebarLarge,
    selectMemberships,
    selectUser
} from "../lib/scraper.slice";
import { Backend, BackendObj } from "../lib/backend";

import { LoadingSpinner, LoadingSpinnerLimit } from "../components/LoadingSpinner";
import { Button, ButtonGroup } from "../components/Button";
import { LongText } from "../components/LongText";
import { ConfirmModal } from "../components/ConfirmModal";
import { CopyTextbox } from "../components/CopyTextbox";
import { OrgPill } from "../components/OrgPill";
import { ExtractJobs } from "../components/ExtractJobs";
import { TextboxModal } from "../components/TextboxModal";
import { Sheet, SheetEditor } from "../components/Sheets";
import { ITab, Tabs } from "../components/Tabs";
import { SidePanel } from "../components/SidePanel";
import { ConfirmationPill } from "../components/ConfirmationPill";
import { CodeBox } from "../components/CodeBox";
import AuditLogHistory from "./AuditLogHistory";
import { Pill } from "../components/Pill";

function EmptyList() {
    const is_sidebar_large = useSelector(selectIsSidebarLarge);

    return <Fragment>
        <div className={classNames("hidden lg:fixed lg:right-0 lg:inset-y-0 lg:flex lg:flex-row", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <div className="flex justify-center items-center h-screen w-full">
                <div className="text-center">
                    <hi.PuzzlePieceIcon className="mx-auto h-12 w-12 text-gray-400" />
                    <h3 className="mt-2 text-sm font-semibold text-gray-900">No integrations</h3>
                    <p className="mt-1 text-sm text-gray-500">Get started by setting up your first integration.</p>
                    <div className="mt-6">
                        <Button icon={hi.PlusIcon} text="Create Integration" href="/endpoint/new" />
                    </div>
                </div>
            </div>
        </div>
        <div className="lg:hidden flex justify-center pt-20 h-screen w-full">
            <div className="text-center">
                <hi.PuzzlePieceIcon className="mx-auto h-12 w-12 text-gray-400" />
                <h3 className="mt-2 text-sm font-semibold text-gray-900">No integrations</h3>
                <p className="mt-1 text-sm text-gray-500">Get started by setting up your first integration.</p>
                <div className="mt-6">
                    <Button icon={hi.PlusIcon} text="Create Integration" href="/endpoint/new" />
                </div>
            </div>
        </div>
    </Fragment>;
}

type EndpointToLookupTableProps = {
    can_edit: boolean;
    source_type: "endpoint" | "lookup_table";
    source_uuid: string;
    endpoint_to_lookup_table_mappings?: t.IEndpointToLookupTable[];
    is_deleting: boolean;
    deleteMapping: (endpoint_to_lookup_table_uuid: string) => Promise<void>;
}

function EndpointToLookupTable(props: EndpointToLookupTableProps) {
    const { can_edit, source_type, source_uuid, endpoint_to_lookup_table_mappings, is_deleting, deleteMapping } = props;

    const [confirm_mapping_uuid, setConfirmMappingUuid] = useState<string | undefined>(undefined);

    return <div className="">
        {endpoint_to_lookup_table_mappings !== undefined && endpoint_to_lookup_table_mappings.length > 0 &&
            <table className="w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                    <tr>
                        <th className="w-1 px-3 py-2 text-left text-sm font-semibold text-gray-900">Endpoint</th>
                        <th className="w-1 px-3 py-2 text-left text-sm font-semibold text-gray-900">Lookup Table</th>
                        <th className="w-1 px-3 py-2 text-left text-sm font-semibold text-gray-900">Created</th>
                        {can_edit && <th className="w-1 px-3 py-2 text-left text-sm font-semibold text-gray-900"></th>}
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                    {endpoint_to_lookup_table_mappings.map((mapping, idx) => (
                        <tr key={idx}>
                            <td className="px-3 py-2 text-sm align-middle">{mapping.endpoint_name}</td>
                            <td className="px-3 py-2 text-sm align-middle">{mapping.lookup_table_name}</td>
                            <td className="px-3 py-2 text-sm align-middle">{prettySmartDateTime(mapping.created_at)}</td>
                            {can_edit && <td className="px-3 py-2 text-sm text-right">
                                <Button
                                    icon={hi.PencilIcon}
                                    text=""
                                    disabled={is_deleting}
                                    href={`/endpoint-lookup-table-mapping/edit/${source_type}/${source_uuid}/${mapping.uuid}`} />
                                <Button
                                    icon={is_deleting ? undefined : hi.TrashIcon}
                                    text=""
                                    loading={is_deleting}
                                    disabled={is_deleting}
                                    onClick={() => setConfirmMappingUuid(mapping.uuid)} />
                            </td>}
                        </tr>
                    ))}
                </tbody>
            </table>}
        {endpoint_to_lookup_table_mappings && endpoint_to_lookup_table_mappings.length === 0 && <span className="text-sm">No connected endpoints</span>}
        {!endpoint_to_lookup_table_mappings && <LoadingSpinnerLimit />}
        <ConfirmModal
            open={confirm_mapping_uuid !== undefined}
            title="Delete Mapping"
            message={["Are you sure you want to delete this mapping?"]}
            confirm="Delete"
            onClose={(result: boolean) => {
                if (result && confirm_mapping_uuid) {
                    deleteMapping(confirm_mapping_uuid);
                }
                setConfirmMappingUuid(undefined);
            }} />
    </div>;
}

type EndpointExtractJobsProps = {
    endpoint: t.IEndpoint;
}

function EndpointExtractJobs(props: EndpointExtractJobsProps) {
    const { endpoint } = props;

    return <div className="px-4 py-6">
        <ExtractJobs type="endpoint" endpoint_uuid={endpoint.uuid} org_uuid={endpoint.org_uuid} />
    </div>;
}

type EndpointDocsProps = {
    endpoint: t.IEndpoint;
}

function EndpointDocs(props: EndpointDocsProps) {
    const { endpoint } = props;

    return <Fragment>
        <div className="mx-4 px-4 mt-3 pt-3 pb-3 sm:px-0 text-sm bg-sky-50 rounded-lg">
            <div className="m-4 mb-4">
                Asynchronous endpoint for extracting data from text using templates.
                POST request creates a job and returns a job token. GET request retrieves job status and results.
                If integration has a webhook, results are also sent to the webhook URL.
            </div>
            <div className="m-4 mb-4">
                <Button
                    icon={hi.ArrowTopRightOnSquareIcon}
                    href="https://gist.github.com/blazf/979940346e66f65ac048734f9e507ac4"
                    text="OpenAPI"
                    open_in_new_tab={true} />
                <Button
                    icon={hi.ArrowTopRightOnSquareIcon}
                    href="https://gist.github.com/blazf/3bb9abf9bab1d968b06413c36005337e"
                    text="Node.JS example"
                    open_in_new_tab={true} />
            </div>
        </div>
        <div className="px-4 py-6 sm:px-0 text-sm">
            <div className="mx-4 mb-2 font-medium">Create job - POST request</div>
            <div className="m-4 mb-2">
                Header Parameters:
                <ul className="list-disc list-inside ml-4">
                    <li><code>Content-Type: application/json</code> - payload in body must be a JSON</li>
                </ul>
            </div>
            <div className="m-4 mb-2">
                Body must be a valid JSON with the following keys:
                <ul className="list-disc list-inside ml-4">
                    <li><code>name: string</code> - name of the job</li>
                    <li><code>input_text: string</code> - <i>[optional]</i> raw text to process</li>
                    <li><code>input_file: object</code> - <i>[optional]</i> raw text to process</li>
                    <li><code>passthrough_data</code> - <i>[optional]</i> any additional data to pass through to the webhook call</li>
                </ul>
            </div>
            <div className="m-4 mb-2">
                Keys <code>input_text</code> and <code>input_file</code> are mutually exclusive. Exactly one must be present.
            </div>
            <div className="m-4 mb-2">
                Input file is an object with the following keys:
                <ul className="list-disc list-inside ml-4">
                    <li><code>filename: string</code> - name of the file</li>
                    <li><code>mimetype: string</code> - MIME type of the file, if unknown use <code>octet/stream</code></li>
                    <li><code>base64: string</code> - base64 encoded file content</li>
                </ul>
            </div>
            <div className="m-4 mb-2">
                Result is a JSON with the following key:
                <ul className="list-disc list-inside ml-4">
                    <li><code>job_uuid</code> - job token</li>
                </ul>
            </div>
            <div className="mx-4 mt-8 mb-2 font-medium">Example for <code>input_text</code></div>
            <div className="m-4 mb-2">Input:</div>
            <CodeBox language={"JSON"} code={`{\n    "name": "Job Text",\n    "input_text": "Content"\n}`} />
            <div className="m-4 mb-2">Call using curl:</div>
            <CodeBox language={"curl"} code={`curl -X POST "${endpoint.url}" \\\n    -H "Content-Type: application/json" \\\n    -H "x-api-key: API_KEY" \\\n    -d "{\\"name\\": \\"Job 01\\", \\"input_text\\": \\"Content\\"}"`} />
            <div className="m-4 mb-2">Result:</div>
            <CodeBox language={"JSON"} code={`{\n    "job_uuid": "aaa111bbb222ccc333ddd444"\n}`} />

            <div className="mx-4 mt-8 mb-2 font-medium">Example for <code>input_file</code></div>
            <div className="m-4 mb-2">Input:</div>
            <CodeBox language={"JSON"} code={`{\n    "name": "Job File",\n    "input_file": {\n        filename: "example.pdf",\n        mimetype: "application/pdf",\n        base64: "JVBERi0xLjQKJcfsj6IKNS..."\n    },\n    passthrough_data: {\n        some_key: "some_value"\n    }\n}`} />
            <div className="m-4 mb-2">Result:</div>
            <CodeBox language={"JSON"} code={`{\n    "job_uuid": "aaa111bbb222ccc333ddd444"\n}`} />

        </div>
        <div className="px-4 py-6 sm:px-0 text-sm">
            <div className="mx-4 mb-2 font-medium">Retrieve job - GET request</div>
            <div className="m-4 mb-2">
                URL Parameters:
                <ul className="list-disc list-inside ml-4">
                    <li><code>job_uuid</code> - job token</li>
                </ul>
            </div>
            <div className="m-4 mb-2">
                Result:
                <ul className="list-disc list-inside ml-4">
                    <li><code>status</code> - current job status</li>
                    <li><code>result</code> - job results, present when job is done</li>
                </ul>
            </div>
            <div className="mx-4 mt-8 mb-2 font-medium">Example </div>
            <div className="m-4 mb-2">Call using curl:</div>
            <CodeBox language={"curl"} code={`curl "${endpoint.url}?job_uuid=aaa111bbb222ccc333ddd444 \\\n    -H "x-api-key: API_KEY"`} />
            <div className="m-4 mb-2">Result:</div>
            <CodeBox language={"JSON"} code={`{
  PO: [
    {
      sender: "jane.demaggio@tier2.com",
      SKU: "100356000",
      quantity: "10500",
      delivery_date: "10-08-2023",
      status: "confirmed"
    },
    {
      sender: "jane.demaggio@tier2.com",
      SKU: "100356000",
      quantity: "20000",
      delivery_date: "2023-09-30",
      status: "pending"
    }
  ]
}`} />
        </div>
    </Fragment>
}

function WebhookDocs() {
    return <div className="px-4 pt-3 pb-3 sm:px-0 text-sm ">
        <div className="m-4 mb-4">
            Webhook is called when a job is done. It sends the results in the body of the POST request.
        </div>
        <div className="m-4 mb-4">
            If API key is set, it is sent in the header as <code>X-API-KEY</code>.
        </div>

        <div className="px-4 py-6 sm:px-0 text-sm">
            <div className="mx-4 mb-2 font-medium">Webhook payload</div>
            <div className="m-4 mb-2">
                Payload contains the following keys:
                <ul className="list-disc list-inside ml-4">
                    <li><code>id</code> - unique identifier for each payload</li>
                    <li><code>job_uuid</code> - uuid of the job that triggered the webhook</li>
                    <li><code>created</code> - timestamp when the payload was created</li>
                    <li><code>api_version</code> - version of the API that triggered the webhook</li>
                    <li><code>type</code> - type of the payload, always <code>new_extraction</code></li>
                    <li><code>data</code> - results of the job</li>
                </ul>
            </div>
            <div className="m-4 mb-2">
                Data field contains the results of the job. It is a JSON object with the following keys:
                <ul className="list-disc list-inside ml-4">
                    <li><code>value</code> - JSON object representing extracted data</li>
                    <li><code>input</code> - <i>[optional]</i> input data that was processed</li>
                    <li><code>passthrough_data</code> - <i>[optional]</i> data passed through from the REST API call that started the job</li>
                </ul>
            </div>
            <div className="mx-4 mt-8 mb-2 font-medium">Example payload</div>
            <CodeBox language={"JSON"} code={`{
    "id": "0b69ad32fb82425293d198da643efed2",
    "job_uuid": "74b866b393fe41c3a09a9cd23595eb94",
    "created": 1713089109354,
    "api_version": "v2",
    "type": "new_extraction",
    "data": {
        "value": {
            "Contact Address": [
                {
                    "First Name": "John",
                    "Last Name": "Smith",
                    "Address": "Street 123, City, Country",
                }
            ]
        },
        "input": {
            "from": "",
            "subject": "example.xlsx",
            "body": "...",
            "attachments": [
                {
                    "filename": "example.xlsx",
                    "mimetype": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    "content": "UEsDBBQABgAIA..."
                }
            ]
        },
        "passthrough_data": {
            "some_key": "some_value"
        }
    }
}`} />
        </div>

        <div className="px-4 py-6 sm:px-0 text-sm">
            <div className="mx-4 mb-2 font-medium">Webhook call IP address</div>
            <div className="m-4 mb-2">
                Requests come from one of the following IP addresses:
            </div>
            <div className="m-4 mb-2">
                <Button
                    icon={hi.ArrowTopRightOnSquareIcon}
                    href="https://gist.github.com/blazf/8b4d7e0969120cbdd28960ef47d08572"
                    text="Webhook IP whitelist"
                    open_in_new_tab={true} />
            </div>
        </div>
    </div>;
}

type IpWhiteListProps = {
    endpoint: t.IEndpoint;
    updateWhitelist: (ip_whitelist: string[]) => Promise<void>;
}

function IpWhiteList(props: IpWhiteListProps) {
    const { endpoint, updateWhitelist } = props;

    const [ip_whitelist, setIpWhitelist] = useState<string>(endpoint.ip_whitelist.join("\n"));
    const [is_edited, setIsEdited] = useState<boolean>(false);
    const [is_valid, setIsValid] = useState<boolean>(false);
    const [validation_message, setValidationMessage] = useState<string | undefined>(undefined);
    const [is_saving, setIsSaving] = useState<boolean>(false);

    const setIpWhitelistAndMarkEdited = (value: string) => {
        setIpWhitelist(value);
        setIsEdited(true);
        setIsValid(false);
        setValidationMessage(undefined);
    };

    const getIpWhitelist = () => {
        return ip_whitelist
            .split("\n")
            .map((ip_address) => ip_address.trim())
            .filter((ip_address) => ip_address.length > 0);
    }

    const updateEndpoint = async () => {
        setIsSaving(true);
        await updateWhitelist(getIpWhitelist());
        setIsEdited(false);
        setIsSaving(false);
    }

    const validate = () => {
        // empty whitelist is valid
        if (ip_whitelist.length === 0) {
            setIsValid(true);
            setValidationMessage(undefined);
            return;
        }
        // validate each IP address
        const ip_addresses = getIpWhitelist();
        for (const ip_address of ip_addresses) {
            if (ip_address.includes("-")) {
                const ip_range = ip_address.split("-");
                if (ip_range.length !== 2) {
                    setValidationMessage(`Invalid IP address range: '${ip_address}'`);
                    setIsValid(false);
                    return;
                }
                if (!isValidIpAddress(ip_range[0])) {
                    setValidationMessage(`Invalid IP address: '${ip_range[0]}'`);
                    setIsValid(false);
                    return;
                }
                if (!isValidIpAddress(ip_range[1])) {
                    setValidationMessage(`Invalid IP address: '${ip_range[1]}'`);
                    setIsValid(false);
                    return;
                }
            } else if (!isValidIpAddress(ip_address)) {
                setValidationMessage(`Invalid IP address: '${ip_address}'`);
                setIsValid(false);
                return;
            }
        }
        setIsValid(true);
        setValidationMessage(undefined);
    }

    const can_save = is_edited && is_valid;

    return <div className="px-4 pt-3 pb-3 sm:px-0 text-sm ">
        <div className="m-4 mb-4">
            List of IP addresses that can call the Rest API endpoint.
        </div>
        <div className="m-4 mb-4">
            Please enter one IP address per line. If empty, all IP addresses are allowed.
        </div>
        <div className="m-4 mb-4">
            For IP ranges use the following format: <code>192.168.1.30-192.168.1.40</code>.
        </div>
        <div className="m-4 mb-4">
            <textarea
                className={classNames("w-full h-32 p-2 rounded-lg text-sm font-mono", ip_whitelist.length === 0 ? "bg-gray-50" : "bg-white")}
                value={ip_whitelist}
                placeholder="Enter IP addresses here"
                onChange={(e) => setIpWhitelistAndMarkEdited(e.target.value)} />
        </div>
        {validation_message && <div className="m-4 mb-4 text-red-500">{validation_message}</div>}
        <div className="m-4 mb-4 text-right">
            <Button text="Validate" onClick={validate} />
            <Button text="Save" disabled={!can_save} onClick={updateEndpoint} loading={is_saving} highlight={true} />
        </div>
    </div>;
}

type CopyTextboxProps = {
    showText: () => void;
}

export function HiddenTextbox(props: CopyTextboxProps) {
    const { showText } = props;

    return <div className="flex text-sm">
        <input
            type="text"
            value="••••••••••••••••••••••••••••"
            readOnly
            className="flex-grow text-sm px-2 py-1 rounded-l-md border-gray-300 outline-none truncate"
        />
        <button
            onClick={showText}
            className="px-4 py-2 bg-sky-600 text-white rounded-r-md hover:bg-sky-500"
        >
            Show
        </button>
    </div>;
}

type WebApiKeyListProps = {
    endpoint: t.IEndpoint;
    createNewKey: (name: string) => Promise<void>;
    showKey: (api_key_uuid: string) => void;
    deleteKey: (api_key_uuid: string) => Promise<void>;
}

function WebApiKeyList(props: WebApiKeyListProps) {
    const { endpoint, showKey } = props;

    const [keys, setKeys] = useState<t.IWebApiKey[] | undefined>(undefined);
    const [show_create_modal, setShowCreateModal] = useState(false);
    const [show_confirm_modal, setShowConfirmModal] = useState(false);
    const [delete_key, setDeleteKey] = useState<t.IWebApiKey | undefined>(undefined);

    useEffect(() => {
        setKeys(endpoint.keys);
    }, [endpoint]);

    const createNewKey = async (result: boolean, name?: string) => {
        setShowCreateModal(false);
        if (result && name !== undefined) {
            await props.createNewKey(name);
        }
    };

    const checkDeleteKey = async (key: t.IWebApiKey) => {
        setDeleteKey(key);
        setShowConfirmModal(true);
    };

    const deleteKey = async (result: boolean) => {
        setShowConfirmModal(false);
        if (delete_key && result) {
            await props.deleteKey(delete_key.uuid);
        }
    };

    return <div className="px-4 py-6">
        <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
                <tr>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Name</th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Key</th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Created</th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Last used</th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-36"></th>
                </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
                {keys && keys.map((key) => (
                    <tr key={key.uuid}>
                        <td className="px-4 py-2 text-sm">{key.name}</td>
                        <td className="px-4 py-2 text-xs font-mono">
                            {key.key.length > 0 && <CopyTextbox text={key.key} is_email={false} />}
                            {key.key.length === 0 && <HiddenTextbox showText={() => showKey(key.uuid)} />}
                        </td>
                        <td className="px-4 py-2 text-sm">{prettyDateTime(key.created_at)}</td>
                        <td className="px-4 py-2 text-sm">{key.last_used_at ? prettyDateTime(key.last_used_at) : "-"}</td>
                        <td className="px-4 py-2 text-sm text-right">
                            <Button icon={hi.TrashIcon} text="Delete" onClick={() => checkDeleteKey(key)} />
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
        <div className="m-4 mb-4 text-right">
            <Button text="Create new key" onClick={() => setShowCreateModal(true)} />
        </div>

        <TextboxModal
            open={show_create_modal}
            title="Create new API key"
            message={"Enter a name for the new API key."}
            confirm="Create"
            validate={(name) => name.length > 0}
            onClose={createNewKey} />

        <ConfirmModal
            open={show_confirm_modal}
            title="Delete API key"
            message={[`Are you sure you want to delete API key '${delete_key?.name}'?`]}
            confirm="Delete"
            onClose={deleteKey} />
    </div>
}

function ForwardEmailItems(props: { items: IEndpointForwardEmailItem[] }) {
    const { items } = props;

    return <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
            <tr>
                <th className="w-1" />
                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Date</th>
                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">From</th>
                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Subject</th>
                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
            </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
            {items.map((item, idx) => (
                <tr key={idx}>
                    <td><ConfirmationPill status={(item.extract_confirmations_status ?? "") as ExtractConfirmationStatus} /></td>
                    <td className="px-4 py-2 text-sm">{prettySmartDateTime(item.created_at)}</td>
                    <td className="px-4 py-2 text-sm">{item.user_first_name} {item.user_last_name} ({item.user_email})</td>
                    <td className="px-4 py-2 text-sm">{item.name}</td>
                    <td className="px-4 py-2 text-sm">{item.extract_confirmations_status ?? ""}</td>
                </tr>
            ))}
        </tbody>
    </table>;
}

export function EndpointDetail() {
    const navigate = useNavigate();
    const { endpoint_uuid } = useParams<{ endpoint_uuid: string | undefined }>();

    const env = useSelector(selectEnv);
    const is_sidebar_large = useSelector(selectIsSidebarLarge);
    const user = useSelector(selectUser);
    const membership = useSelector(selectMemberships);

    const [endpoint, setEndpoint] = useState<t.IEndpoint | undefined>(undefined);
    const [has_lookup_tables, setHasLookupTables] = useState<boolean>(false);
    const [endpoint_to_lookup_table_mappings, setEndpointToLookupTableMappings] = useState<t.IEndpointToLookupTable[] | undefined>(undefined);
    const [is_deleting, setIsDeleting] = useState<boolean>(false);
    const [is_cloning, setIsCloning] = useState(false);
    const [is_sending_email_digest, setIsSendingEmailDigest] = useState<boolean>(false);
    const [selected_tab, setSelectedTab] = useState("extract_jobs");
    const [show_email_digest_modal, setShowEmailDigestModal] = useState(false);
    const [show_admin_details, setShowAdminDetails] = useState<boolean>(false);
    const [show_confirm, setShowConfirm] = useState(false);

    useEffect(() => {
        if (endpoint_uuid === undefined) { return; }
        BackendObj.extractions.getEndpoint({ endpoint_uuid }).then(({ endpoint }) => {
            if (endpoint !== undefined) {
                setEndpoint(endpoint);
                BackendObj.extractions.listEndpointToLookupTableMappings({ endpoint_uuid })
                    .then(({ mappings }) => { setEndpointToLookupTableMappings(mappings); })
                    .catch((error) => { console.error(error); setEndpointToLookupTableMappings(undefined); });
                Backend.getLookupTables().then((lookup_tables) => {
                    setHasLookupTables(lookup_tables.filter(lt => lt.org_uuid === endpoint.org_uuid).length > 0);
                });
            }
        });
    }, [endpoint_uuid]);

    useEffect(() => {
        if (endpoint === undefined) {
            setDocumentTitle("Endpoint", env);
        } else {
            setDocumentTitle(`Endpoint - ${endpoint.name}`, env);
        }
    }, [endpoint, env]);

    const updateWhitelist = async (ip_whitelist: string[]) => {
        if (endpoint === undefined) { return; }
        await BackendObj.extractions.updateEndpointIPWhitelist({
            endpoint_uuid: endpoint.uuid,
            ip_whitelist
        });
        setEndpoint({
            ...endpoint,
            ip_whitelist
        });
    };

    const createNewKey = async (name: string) => {
        if (endpoint === undefined) { return; }
        const { key } = await BackendObj.extractions.createEndpointAPIKey({ endpoint_uuid: endpoint.uuid, name });
        setEndpoint({
            ...endpoint,
            keys: [...endpoint.keys, key]
        });
    };

    const showKey = async (api_key_uuid: string) => {
        if (endpoint === undefined) { return; }
        const selected_key = await BackendObj.extractions.getEndpointAPIKey({ endpoint_uuid: endpoint.uuid, key_uuid: api_key_uuid });
        if (selected_key === undefined) { return; }
        const keys = endpoint.keys.map((key) => (key.uuid === api_key_uuid) ? selected_key.key : key);
        setEndpoint({
            ...endpoint,
            keys
        });
    };

    const deleteKey = async (api_key_uuid: string) => {
        if (endpoint === undefined) { return; }
        await BackendObj.extractions.deleteEndpointAPIKey({ endpoint_uuid: endpoint.uuid, key_uuid: api_key_uuid });
        const keys = endpoint.keys.filter((key) => key.uuid !== api_key_uuid);
        setEndpoint({
            ...endpoint,
            keys
        });
    };

    const cloneEndpoint = async () => {
        if (endpoint === undefined) { return; }
        // commit as new endpoint
        setIsCloning(true);
        try {
            const { endpoint_uuid: cloned_endpoint_uuid } = await BackendObj.extractions.createEndpoint({
                org_uuid: endpoint.org_uuid,
                user_uuid: endpoint.user_uuid,
                type: endpoint.type,
                name: `Copy of ${endpoint.name}`,
                description: endpoint.description,
                ip_whitelist: endpoint.ip_whitelist,
                details: endpoint.details,
                output_sheets: endpoint.output_sheets,
                output_name: endpoint.output_name,
                template_uuid: endpoint.template_uuid
            });
            if (cloned_endpoint_uuid !== undefined) {
                navigate(`/endpoint/${cloned_endpoint_uuid}`);
            }
        } catch (error) {
            console.error(error);
        }
        setIsCloning(false);
    };

    const deleteMapping = async (endpoint_to_lookup_table_uuid: string) => {
        setIsDeleting(true);
        await BackendObj.extractions.deleteEndpointToLookupTableMapping({ endpoint_to_lookup_table_uuid });
        if (endpoint_to_lookup_table_mappings !== undefined) {
            const new_endpoint_to_lookup_table_mappings = endpoint_to_lookup_table_mappings.filter((mapping) => mapping.uuid !== endpoint_to_lookup_table_uuid);
            setEndpointToLookupTableMappings(new_endpoint_to_lookup_table_mappings);
        }
        setIsDeleting(false);
    }

    const onRemoveClose = async (is_remove: boolean) => {
        setShowConfirm(false);
        if (is_remove && endpoint_uuid !== undefined) {
            setIsDeleting(true);
            // delete from backend
            await BackendObj.extractions.deleteEndpoint({ endpoint_uuid });
            // navigate back to list
            navigate("/endpoints");
        }
    };

    const sendEmailDigest = async () => {
        if (endpoint === undefined) { return; }
        setIsSendingEmailDigest(true);
        await BackendObj.extractions.sendEmailDigest({ endpoint_uuid: endpoint.uuid });
        const { endpoint: new_endpoint } = await BackendObj.extractions.getEndpoint({ endpoint_uuid: endpoint.uuid });
        if (new_endpoint !== undefined && new_endpoint.uuid === endpoint.uuid) {
            setEndpoint(new_endpoint);
        }
        setIsSendingEmailDigest(false);
    }

    if (endpoint === undefined) {
        return <div className={classNames("hidden lg:fixed lg:right-0 lg:inset-y-0 lg:flex lg:flex-row", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <LoadingSpinner />
        </div>;
    }

    const is_admin = user.role === USER_ROLES.admin;
    const is_org_admin = membership.filter((m) => m.org.uuid === endpoint?.org_uuid && m.role === "admin").length > 0;

    const tabs: ITab[] = [
        { name: "Call Log", key: "extract_jobs" },
        { name: "Details", key: "details" },
        { name: "IP Whitelist", key: "ip_whitelist", hide: !(endpoint.type === ENDPOINT_TYPE.rest_api && is_org_admin) },
        { name: "API Keys", key: "api_keys", hide: !(endpoint.type === ENDPOINT_TYPE.rest_api && is_org_admin) },
        { name: "Rest API Docs", key: "api_docs", hide: !(endpoint.type === ENDPOINT_TYPE.rest_api && is_org_admin) },
        { name: "History", key: "history", only_admin: true },
        { name: "Webhook Docs", key: "webhook_docs", hide: !is_org_admin },
        { name: "Connected Tables", key: "connected_tables", hide: !has_lookup_tables }
    ];

    return <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
        <div className="h-20 w-full bg-white border-b border-b-gray-200">
            <div className="pl-4 pr-10 py-4 flex flex-row items-start max-w-5xl">
                <Button icon={hi.ArrowLeftIcon} onClick={() => navigate("/endpoints")} />
                <div className="pl-4 flex flex-col justify-start gap-1">
                    <h2 className="text-xl font-semibold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight">
                        {endpoint.name}
                    </h2>
                    <h2 className="text-sm text-gray-400 tracking-tight">
                        {endpoint.type === ENDPOINT_TYPE.email && "Email Integration"}
                        {endpoint.type === ENDPOINT_TYPE.rest_api && "Rest API Integration"}
                    </h2>
                </div>
                <div className="grow"></div>
                <div className="flex items-center">
                    {endpoint && is_org_admin &&
                        <ButtonGroup disabled={is_deleting || is_cloning}
                            buttons={[
                                { icon: hi.DocumentDuplicateIcon, text: "", onClick: cloneEndpoint, tooltip: "Clone" },
                                { icon: hi.PencilSquareIcon, text: "", href: `/endpoint/${endpoint.uuid}/edit`, tooltip: "Edit" },
                                { icon: hi.TrashIcon, text: "", onClick: () => setShowConfirm(true), tooltip: "Remove" }
                            ]} />}
                    {endpoint && <Button text="Extract" href={`/extraction/new/${endpoint?.uuid}`} highlight={true} />}
                    {!endpoint && <Button text="Extract" href={`/extraction/new/`} highlight={true} />}
                </div>
            </div>
        </div>

        <div className="px-10 py-7 max-w-5xl">
            <div className="py-3 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Type</dt>
                <dd className="flex fle-row items-center pr-4 text-sm leading-6 text-gray-500 sm:col-span-4 sm:mt-0">
                    {endpoint.type === ENDPOINT_TYPE.email && <hs.EnvelopeIcon className="h-4 w-4 mr-2 text-slate-300" />}
                    {endpoint.type === ENDPOINT_TYPE.email && "Email"}
                    {endpoint.type === ENDPOINT_TYPE.rest_api && <hs.Cog8ToothIcon className="h-4 w-4 mr-2 text-slate-300" />}
                    {endpoint.type === ENDPOINT_TYPE.rest_api && "Rest API"}
                </dd>
            </div>
            {endpoint.type === ENDPOINT_TYPE.rest_api && <div className="py-3 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Owner</dt>
                <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-4 sm:mt-0">
                    {endpoint.user && `${endpoint.user.first_name} ${endpoint.user.last_name} (${endpoint.user.email})`}
                    {!endpoint.user && <span className="text-red-600">Owner of the integration is no longer part of organization</span>}
                </dd>
            </div>}
            <div className="pt-3 pb-6 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-0 border-b border-gray-200">
                <dt className="text-sm font-medium leading-6 text-gray-900">Template</dt>
                <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-4 sm:mt-0">
                    <Link to={`/template/${endpoint.template.uuid}`} className="hover:underline">{endpoint.template.name}</Link>
                </dd>
            </div>
            {endpoint.type === ENDPOINT_TYPE.email && <div className="py-6 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-0 items-center">
                <dt className="text-sm font-medium leading-6 text-gray-900">Email connector</dt>
                <dd className="pr-4 text-sm leading-6 text-sky-600 sm:col-span-4 sm:mt-0">
                    <CopyTextbox text={endpoint.email_address} email_pretty_name={endpoint.name} is_email={true} />
                </dd>
            </div>}
            {endpoint.type === ENDPOINT_TYPE.rest_api && <div className="py-6 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-0 items-center">
                <dt className="text-sm font-medium leading-6 text-gray-900">Endpoint URL</dt>
                <dd className="pr-4 text-sm leading-6 text-sky-600 sm:col-span-4 sm:mt-0">
                    <CopyTextbox text={endpoint.url} is_email={false} />
                </dd>
            </div>}

            <div className="py-6 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-0  items-center">
                <dt className="text-sm font-medium leading-6 text-gray-900">Forward email</dt>
                <dd className="pr-4 text-sm leading-6 text-sky-600 sm:col-span-4 sm:mt-0">
                    {endpoint.details.forward_email_address.length > 0 && <CopyTextbox text={endpoint.details.forward_email_address} is_email={true} />}
                    {endpoint.details.forward_email_address.length === 0 && "/"}
                </dd>
            </div>

            {endpoint.details.passthrough_email_address !== "" && <div className="py-6 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-0  items-center">
                <dt className="text-sm font-medium leading-6 text-gray-900">Passthrough email</dt>
                <dd className="pr-4 text-sm leading-6 text-sky-600 sm:col-span-4 sm:mt-0">
                    {endpoint.details.passthrough_email_address.length > 0 && <CopyTextbox text={endpoint.details.passthrough_email_address} is_email={true} />}
                    {endpoint.details.passthrough_email_address.length === 0 && "/"}
                </dd>
            </div>}

            <div className="py-6 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-0 items-center">
                <dt className="text-sm font-medium leading-6 text-gray-900">Webhook URL</dt>
                <dd className="pr-4 text-sm leading-6 text-sky-600 sm:col-span-4 sm:mt-0">
                    {endpoint.details.webhook_url.length > 0 && <CopyTextbox text={endpoint.details.webhook_url} is_email={false} />}
                    {endpoint.details.webhook_url.length === 0 && "/"}
                </dd>
            </div>

            <div className="pt-6">
                <Tabs tabs={tabs} selected_tab_key={selected_tab} setSelectedTab={setSelectedTab} />
            </div>

            {selected_tab === "extract_jobs" && <EndpointExtractJobs endpoint={endpoint} />}
            {selected_tab === "details" && <div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-10 sm:gap-4 sm:gap-y-8 sm:px-0">
                    <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-900">Store extractions</dt>
                    <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                        {endpoint.details.store_extractions ? "Yes" : "No"}
                    </dd>
                    <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-900">Require confirmation</dt>
                    <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                        {endpoint.details.require_confirmation === "never" && "No"}
                        {endpoint.details.require_confirmation === "on_error" && "Only on Error"}
                        {endpoint.details.require_confirmation === "always" && "Yes"}
                    </dd>
                    <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-900">Join extractions</dt>
                    <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                        {endpoint.details.join_object_extractions ? "Yes" : "No"}
                    </dd>
                    {endpoint.type === ENDPOINT_TYPE.rest_api && <Fragment>
                        <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-900">
                            Unit of processing
                        </dt>
                        <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                            {endpoint.details.unit_of_processing === "all" && "Whole"}
                            {endpoint.details.unit_of_processing === "page" && "Page/Sheet"}
                        </dd>
                    </Fragment>}
                    {endpoint.type === ENDPOINT_TYPE.email && <Fragment>
                        <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-900">Only process attachments</dt>
                        <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                            {endpoint.details.only_process_attachments ? "Yes" : "No"}
                        </dd>
                    </Fragment>}
                    {endpoint.type === ENDPOINT_TYPE.email && <Fragment>
                        <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-900">Unit of processing</dt>
                        <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                            {!endpoint.details.only_process_attachments && "/"}
                            {endpoint.details.only_process_attachments && endpoint.details.unit_of_processing === "all" && "All files"}
                            {endpoint.details.only_process_attachments && endpoint.details.unit_of_processing === "file" && "File"}
                            {endpoint.details.only_process_attachments && endpoint.details.unit_of_processing === "page" && "Page/Sheet"}
                        </dd>
                    </Fragment>}
                    {endpoint.type === ENDPOINT_TYPE.email && <Fragment>
                        <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-900">Reply to the sender</dt>
                        <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                            {endpoint.details.reply_to_sender ? "Yes" : "No"}
                        </dd>
                    </Fragment>}
                    {endpoint.type === ENDPOINT_TYPE.email && <Fragment>
                        <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-900">Include input with reply email</dt>
                        <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                            {endpoint.details.reply_include_input === "no" && "No"}
                            {endpoint.details.reply_include_input === "attachments" && "Attachments only"}
                            {endpoint.details.reply_include_input === "all" && "Email and attachments"}
                        </dd>
                    </Fragment>}
                    <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-900">Result attachment format</dt>
                    <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                        {[
                            { key: "excel", name: "Excel" },
                            { key: "csv", name: "CSV (comma)" },
                            { key: "csv-semicolon", name: "CSV (semicolon)" },
                            { key: "tsv", name: "TSV" },
                            { key: "pdf", name: "PDF" },
                            { key: "json", name: "JSON" },
                            { key: "custom", name: "Custom" }
                        ].find((f) => f.key === endpoint.details.reply_file_format)?.name || "/"}
                    </dd>
                    {endpoint.details.reply_file_format === "custom" && <Fragment>
                        <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-900">Result file extension</dt>
                        <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                            {endpoint.details.reply_file_extension}
                        </dd>
                        <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-900">Result file mimetype</dt>
                        <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                            {endpoint.details.reply_file_mimetype}
                        </dd>
                    </Fragment>}

                    <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-900">Custom extractions filename</dt>
                    <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                        {endpoint.output_name.length > 0 ? "Yes" : "No"}
                    </dd>
                    <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-900">Custom result template</dt>
                    <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                        {endpoint.output_sheets.length > 0 ? "Yes" : "No"}
                    </dd>
                    {endpoint.type === ENDPOINT_TYPE.rest_api && <Fragment>
                        <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-900">REST API version</dt>
                        <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                            {endpoint.details.rest_api_version}
                        </dd>
                    </Fragment>}
                </div>

                {endpoint.details.forward_email_address.length > 0 && <div className="px-4 pb-6 py-3 sm:grid sm:grid-cols-10 sm:gap-6 sm:gap-y-8 sm:px-0 border-t border-gray-200">
                    <dt className="pl-4 text-sm font-bold leading-6 text-gray-900 sm:col-span-10">Forward email details:</dt>
                    <dt className="pl-4 text-sm font-medium leading-6 text-gray-900 sm:col-span-3">Daily digest</dt>
                    <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                        {endpoint.details.forward_email_frequency === "daily" ? "Yes" : "No"}
                    </dd>
                    {endpoint.forward_email_status !== undefined && <Fragment>
                        <dt className="pl-4 text-sm font-medium leading-6 text-gray-900 sm:col-span-2">Next digest</dt>
                        <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-3 sm:mt-0">
                            {prettyDateTime(endpoint.forward_email_status.next_ts)}
                        </dd>
                        <dt className="pl-4 text-sm font-medium leading-6 text-gray-900 sm:col-span-3">Hour of day</dt>
                        <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                            {getLocalHour(endpoint.details.forward_email_digest_hour_utc)}:00h
                        </dd>
                        <dt className="pl-4 text-sm font-medium leading-6 text-gray-900 sm:col-span-3">Group by field</dt>
                        <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                            {endpoint.template.contexts.flatMap((context) => context.fields)
                                .find((field) => field.uuid === endpoint.details.forward_email_digest_group_field_uuid)?.name || "/"}
                        </dd>
                        <dt className="pl-4 text-sm font-medium leading-6 text-gray-900 sm:col-span-3">Items ready for next digest</dt>
                        <dd className="flex flex-row items-center pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                            {endpoint.forward_email_status.items_confirmed}
                            <hi.ArrowTopRightOnSquareIcon
                                className="h-4 w-4 ml-2 text-gray-400 hover:text-gray-600 cursor-pointer"
                                onClick={() => setShowEmailDigestModal(true)} />
                        </dd>
                        <dt className="pl-4 text-sm font-medium leading-6 text-gray-900 sm:col-span-3">Items awaiting confirmation</dt>
                        <dd className="flex flex-row items-center pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                            {endpoint.forward_email_status.items_pending}
                            <hi.ArrowTopRightOnSquareIcon
                                className="h-4 w-4 ml-2 text-gray-400 hover:text-gray-600 cursor-pointer"
                                onClick={() => setShowEmailDigestModal(true)} />
                        </dd>
                        {is_org_admin && <Fragment>
                            <dt className="pl-4 text-sm font-medium leading-6 text-gray-900 sm:col-span-3">Initiate daily digest</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                                <Button text="Send now" loading={is_sending_email_digest} onClick={sendEmailDigest} />
                            </dd>
                        </Fragment>}
                    </Fragment>}
                </div>}

                {endpoint.details.passthrough_email_address.length > 0 && <div className="px-4 pb-6 py-3 sm:grid sm:grid-cols-10 sm:gap-6 sm:gap-y-8 sm:px-0 border-t border-gray-200">
                    <dt className="pl-4 text-sm font-bold leading-6 text-gray-900 sm:col-span-10">Passthrough email details:</dt>
                    <dt className="pl-4 text-sm font-medium leading-6 text-gray-900 sm:col-span-3">Custom email subject</dt>
                    <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                        {endpoint.details.passthrough_email_subject.length > 0 ? "Yes" : "No"}
                    </dd>
                </div>}

                {endpoint.details.webhook_url.length > 0 && <div className="px-4 pb-6 py-3 sm:grid sm:grid-cols-10 sm:gap-6 sm:gap-y-8 sm:px-0 border-t border-gray-200">
                    <dt className="pl-4 text-sm font-bold leading-6 text-gray-900 sm:col-span-10">Webhook details:</dt>
                    <dt className="pl-4 text-sm font-medium leading-6 text-gray-900 sm:col-span-3">Include input with webhook call</dt>
                    <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                        {endpoint.details.webhook_include_input ? "Yes" : "No"}
                    </dd>
                    <dt className="pl-4 text-sm font-medium leading-6 text-gray-900 sm:col-span-3">Webhook format version</dt>
                    <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                        {endpoint.details.webhook_version}
                    </dd>
                    <dt className="pl-4 text-sm font-medium leading-6 text-gray-900 sm:col-span-3">Webhook retry count</dt>
                    <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                        {endpoint.details.webhook_retry_count}
                    </dd>
                    <dt className="pl-4 text-sm font-medium leading-6 text-gray-900 sm:col-span-3">Debug logging for webhook call</dt>
                    <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                        {endpoint.details.webhook_store_payload === "all" && "All"}
                        {endpoint.details.webhook_store_payload === "none" && "None"}
                        {endpoint.details.webhook_store_payload === "only_failed" && "Only Failed"}
                    </dd>
                    <dt className="pl-4 text-sm font-medium leading-6 text-gray-900 sm:col-span-3">Fail notify email</dt>
                    <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                        {endpoint.details.webhook_notify_on_fail_email_address}
                    </dd>
                    <dt className="pl-4 text-sm font-medium leading-6 text-gray-900 sm:col-span-3">API key in HTTP header</dt>
                    <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                        {(endpoint.details.webhook_api_key === undefined || endpoint.details.webhook_api_key.length === 0) && "/"}
                        {endpoint.details.webhook_api_key !== undefined && endpoint.details.webhook_api_key.length > 0 && "Yes"}
                    </dd>
                </div>}

                {show_admin_details && is_admin && <div className="px-4 py-6 sm:grid sm:grid-cols-10 sm:gap-4 sm:px-0 border-t border-gray-200">
                    <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Truncate Long Text</dt>
                    <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">
                        {endpoint.details.truncate_long_text ? "Yes" : "No"}
                    </dd>
                    <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400"></dt>
                    <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">
                    </dd>
                </div>}

                {!show_admin_details && is_admin && <div className="px-4 py-6 border-t border-gray-200">
                    <Button text="Show admin details" onClick={() => setShowAdminDetails(true)} icon={hi.ChevronDownIcon} />
                </div>}

                {show_admin_details && is_admin && <div className="px-4 py-6 border-t border-gray-200">
                    <Button text="Hide admin details" onClick={() => setShowAdminDetails(false)} icon={hi.ChevronUpIcon} />
                </div>}
            </div>}

            {selected_tab === "ip_whitelist" && <IpWhiteList endpoint={endpoint} updateWhitelist={updateWhitelist} />}
            {selected_tab === "api_keys" && <WebApiKeyList endpoint={endpoint} createNewKey={createNewKey} showKey={showKey} deleteKey={deleteKey} />}
            {selected_tab === "api_docs" && <EndpointDocs endpoint={endpoint} />}
            {selected_tab === "history" && <div className="px-4 py-4">
                <AuditLogHistory entities={[{ uuid: endpoint.uuid, type: "endpoint", name: endpoint.name }]} />
            </div>}
            {selected_tab === "webhook_docs" && <WebhookDocs />}
            {selected_tab === "connected_tables" && <div className="px-4 py-6">
                <div className="pb-3 flex flex-row items-center w-full">
                    <div className="flex-1" />
                    {is_org_admin && <Button icon={hi.PlusCircleIcon} text="Connect" href={`/endpoint-lookup-table-mapping/create/endpoint/${endpoint.uuid}`} />}
                </div>
                <EndpointToLookupTable
                    can_edit={is_org_admin}
                    source_type="endpoint"
                    source_uuid={endpoint.uuid}
                    endpoint_to_lookup_table_mappings={endpoint_to_lookup_table_mappings}
                    is_deleting={is_deleting}
                    deleteMapping={deleteMapping} />
            </div>}
        </div>

        {endpoint.forward_email_status && <SidePanel title="Digest items" size="2xl" open={show_email_digest_modal} onClose={() => setShowEmailDigestModal(false)}>
            <ForwardEmailItems items={endpoint.forward_email_status.items} />
        </SidePanel>}

        <ConfirmModal open={show_confirm}
            title="Remove en"
            message={["Are you sure you want to remove this integration?"]}
            confirm="Remove"
            onClose={onRemoveClose} />
    </div>;
}

export function LookupTableDetail() {
    const navigate = useNavigate();
    const { lookup_table_uuid } = useParams<{ lookup_table_uuid: string | undefined }>();

    const env = useSelector(selectEnv);
    const is_sidebar_large = useSelector(selectIsSidebarLarge);
    const memberships = useSelector(selectMemberships);

    const [lookup_table, setLookupTable] = useState<t.ILookupTable | undefined>(undefined);
    const [lookup_table_rows, setLookupTableRows] = useState<t.ILookupTableRowSlim[] | undefined>(undefined);
    const [has_endpoints, setHasEndpoints] = useState(false);
    const [endpoint_to_lookup_table_mappings, setEndpointToLookupTableMappings] = useState<t.IEndpointToLookupTable[] | undefined>(undefined);
    const [is_deleting, setIsDeleting] = useState(false);
    const [is_activating, setIsActivating] = useState(false);
    const [selected_tab, setSelectedTab] = useState("observed_values");
    const [show_confirm, setShowConfirm] = useState(false);

    useEffect(() => {
        if (lookup_table_uuid === undefined) { return; }
        Backend.getLookupTable({ lookup_table_uuid })
            .then((lookup_table) => {
                if (lookup_table !== undefined) {
                    setLookupTable(lookup_table);
                    BackendObj.extractions.listLookupTableRows({ lookup_table_uuid, header_length: lookup_table.headers.length })
                        .then(({ rows }) => { setLookupTableRows(rows); })
                        .catch((error) => { console.error(error); setLookupTableRows(undefined); });
                    BackendObj.extractions.listEndpointToLookupTableMappings({ lookup_table_uuid })
                        .then(({ mappings }) => { setEndpointToLookupTableMappings(mappings); })
                        .catch((error) => { console.error(error); setEndpointToLookupTableMappings(undefined); });
                    // check if we have any endpoints so we can show the connected endpoints tab
                    BackendObj.extractions.listEndpoints({}).then(({ endpoints }) => {
                        setHasEndpoints(endpoints.filter((endpoint) => endpoint.org_uuid === lookup_table.org_uuid).length > 0);
                    });
                }
            });
    }, [lookup_table_uuid]);

    useEffect(() => {
        if (lookup_table === undefined) {
            setDocumentTitle("Lookup Table", env);
        } else {
            setDocumentTitle(`Lookup Table - ${lookup_table.name}`, env);
        }
    }, [lookup_table, env]);

    const tabs: ITab[] = [
        { name: "Observed Values", key: "observed_values" },
        { name: "Uploaded Data", key: "upload_data" }
    ];
    if (has_endpoints) { tabs.push({ name: "Connected Endpoints", key: "connected_endpoints" }); }

    const onDownload = async (type: "rows" | "upload") => {
        if (lookup_table === undefined) { return; }
        // prepare data
        const data: string[][] = type === "rows" ?
            [lookup_table.headers, ...lookup_table_rows?.map((row) => row.row) || []] :
            lookup_table.active_version?.sheet || [];
        // prepare excel
        const wb = xlsx.utils.book_new();
        const ws = xlsx.utils.aoa_to_sheet(data);
        xlsx.utils.book_append_sheet(wb, ws, "lookup_table");
        // save workbook
        const blob = new Blob([xlsx.write(wb, { bookType: "xlsx", type: "array" })], { type: "application/octet-stream" });
        saveAs(blob, `lookup_table${lookup_table.uuid}.xlsx`);
    };

    const deleteMapping = async (endpoint_to_lookup_table_uuid: string) => {
        setIsDeleting(true);
        await BackendObj.extractions.deleteEndpointToLookupTableMapping({ endpoint_to_lookup_table_uuid });
        if (endpoint_to_lookup_table_mappings !== undefined) {
            const new_endpoint_to_lookup_table_mappings = endpoint_to_lookup_table_mappings.filter((mapping) => mapping.uuid !== endpoint_to_lookup_table_uuid);
            setEndpointToLookupTableMappings(new_endpoint_to_lookup_table_mappings);
        }
        setIsDeleting(false);
    }

    const handleActivate = async (version_uuid: string) => {
        if (lookup_table === undefined) { return; }
        setIsActivating(true);
        // update version
        await BackendObj.extractions.setLookupTableActiveVersion({ lookup_table_uuid: lookup_table.uuid, version_uuid });
        // refresh lookup table
        const updated_lookup_table = await Backend.getLookupTable({ lookup_table_uuid: lookup_table.uuid });
        if (updated_lookup_table !== undefined) {
            setLookupTable(updated_lookup_table);
        }
        setIsActivating(false);
    }

    const onAddRow = async (row: string[]) => {
        if (lookup_table === undefined) { return; }
        const { row: new_row } = await BackendObj.extractions.addLookupTableRow({ lookup_table_uuid: lookup_table.uuid, row });
        if (lookup_table_rows !== undefined) {
            const new_lookup_table_rows = [...lookup_table_rows, new_row];
            setLookupTableRows(new_lookup_table_rows);
        }
    }

    const onDeleteRow = async (row_uuid: string) => {
        await BackendObj.extractions.deleteLookupTableRow({ row_uuid });
        if (lookup_table_rows !== undefined) {
            const new_lookup_table_rows = lookup_table_rows.filter((row) => row.uuid !== row_uuid);
            setLookupTableRows(new_lookup_table_rows);
        }
    }

    const onUpdateRow = async (row_uuid: string, row: string[]) => {
        await BackendObj.extractions.updateLookupTableRow({ row_uuid, row });
        if (lookup_table_rows !== undefined) {
            const new_lookup_table_rows = lookup_table_rows.map((r) => (r.uuid === row_uuid) ? { ...r, row } : r);
            setLookupTableRows(new_lookup_table_rows);
        }
    }

    const onRemoveClose = async (is_remove: boolean) => {
        setShowConfirm(false);
        if (is_remove && lookup_table_uuid !== undefined) {
            setIsDeleting(true);
            // delete from backend
            await Backend.deleteLookupTable({ lookup_table_uuid });
            // navigate back to list
            navigate("/endpoints");
        }
    };

    if (lookup_table === undefined) {
        return <div className={classNames("hidden lg:fixed lg:right-0 lg:inset-y-0 lg:flex lg:flex-row", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <LoadingSpinner />
        </div>;
    }

    const is_org_admin = memberships.filter((m) => m.org.uuid === lookup_table.org_uuid && m.role === "admin").length > 0;
    const is_email_enabled = lookup_table.details.email_update !== undefined && lookup_table.details.email_update !== "disabled";

    // reverse versions so the latest is first and truncate to 100
    const reverse_versions = [...lookup_table.versions].reverse().slice(0, 100);
    const hash_keys = lookup_table.details.merge_key_column_indices?.map((idx) => lookup_table.headers[idx]) || [];
    const compensate_leading_zeros_keys = lookup_table.details.compensate_leading_zeros_column_indices?.map((idx) => lookup_table.headers[idx]) || [];

    return <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
        <div className="h-20 w-full bg-white border-b border-b-gray-200">
            <div className="pl-4 pr-10 py-4 flex flex-row items-start max-w-5xl">
                <Button icon={hi.ArrowLeftIcon} onClick={() => navigate("/endpoints")} />
                <div className="pl-4 flex flex-col justify-start gap-1">
                    <h2 className="text-xl font-semibold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight">
                        {lookup_table.name}
                    </h2>
                    <h2 className="text-sm text-gray-400 tracking-tight">Lookup Table</h2>
                </div>
                <div className="grow"></div>
                <div className="flex items-center">
                    {lookup_table && is_org_admin && <ButtonGroup disabled={is_deleting}
                        buttons={[
                            { icon: hi.PencilSquareIcon, text: "Edit", href: `/lookup_table/edit/${lookup_table.uuid}` },
                            { icon: hi.ArrowUpTrayIcon, text: "Upload", href: `/lookup_table/upload/${lookup_table.uuid}` },
                            { icon: hi.TrashIcon, text: "Remove", onClick: () => setShowConfirm(true) }
                        ]} />}
                </div>
            </div>
        </div>

        <div className="px-10 py-4 max-w-5xl">
            <div className="py-3 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Type</dt>
                <dd className="flex fle-row items-center pr-4 text-sm leading-6 text-gray-500 sm:col-span-4 sm:mt-0">
                    <hs.TableCellsIcon className="h-4 w-4 mr-2 text-slate-300" />Lookup Table
                </dd>
            </div>
            <div className="py-3 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Headers</dt>
                <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-4 sm:mt-0 outer-div">
                    <table className="py-4 text-xs md:text-base">
                        <thead>
                            <tr>
                                {lookup_table.headers.map((_header, idx) => <th key={idx}
                                    className="py-1 px-4 bg-gray-50 border border-gray-300 text-gray-900 text-xs font-normal align-top w-32 focus:ring-1 focus:ring-sky-500 min-w-[150px] max-w-[300px]"
                                >
                                    {getExcelColumnName(idx)}
                                </th>)}
                            </tr>
                            <tr>
                                {lookup_table.headers.map((header, idx) => <th key={idx}
                                    className="py-1 px-4 bg-gray-100 border border-gray-300 text-gray-900 cursor-text hover:bg-sky-100 text-left text-sm font-normal align-top w-32 focus:ring-1 focus:ring-sky-500 min-w-[150px] max-w-[300px]"
                                >
                                    {header}
                                </th>)}
                            </tr>
                        </thead>
                    </table>
                </dd>
            </div>
            <div className="pt-3 pb-6 sm:grid sm:grid-cols-10 sm:gap-x-4 sm:gap-y-6 sm:px-0 items-center border-b border-gray-200">
                <dt className="text-sm font-medium leading-6 text-gray-900 sm:col-span-2">Merge keys</dt>
                <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-8 sm:mt-0">
                    {hash_keys.length === 0 && "/"}
                    {hash_keys.length > 0 && hash_keys.join(", ")}
                </dd>
            </div>

            <div className="pt-3 pb-6 sm:grid sm:grid-cols-10 sm:gap-x-4 sm:gap-y-6 sm:px-0 items-center border-b border-gray-200">
                <dt className="text-sm font-medium leading-6 text-gray-900 sm:col-span-2">Compensate leading zeros</dt>
                <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-8 sm:mt-0">
                    {compensate_leading_zeros_keys.length === 0 && "/"}
                    {compensate_leading_zeros_keys.length > 0 && compensate_leading_zeros_keys.join(", ")}
                </dd>
            </div>

            {is_email_enabled && <div className="py-6 sm:grid sm:grid-cols-10 sm:gap-x-4 sm:gap-y-6 sm:px-0 items-center border-b border-gray-200">
                <dt className="text-sm font-medium leading-6 text-gray-900 sm:col-span-2">Email connector</dt>
                <dd className="pr-4 text-sm leading-6 text-sky-600 sm:col-span-8 sm:mt-0">
                    <CopyTextbox text={lookup_table.email_address} email_pretty_name={`Update lookup table '${lookup_table.name}'`} is_email={true} />
                </dd>
                <dt className="text-sm font-medium leading-6 text-gray-900 sm:col-span-3">Upload via Email</dt>
                <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                    {[
                        { key: "replace", name: "Upload" },
                        { key: "append", name: "Append" },
                        { key: "merge_incoming_wins", name: "Merge incoming wins" },
                        { key: "merge_existing_wins", name: "Merge existing wins" }
                    ].find((f) => f.key === lookup_table.details.email_update)?.name || "/"}
                </dd>
                <dt className="text-sm font-medium leading-6 text-gray-900 sm:col-span-3">Attachment format</dt>
                <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                    {[
                        { key: "tsv", name: "TSV" }
                    ].find((f) => f.key === lookup_table.details.email_attachment_format)?.name || "TSV"}
                </dd>
            </div>}
        </div>

        <div className="px-10 max-w-5xl">
            <div className="pt-6">
                <Tabs tabs={tabs} selected_tab_key={selected_tab} setSelectedTab={setSelectedTab} />
            </div>

            {selected_tab === "observed_values" && lookup_table_rows &&
                <div className="px-4 py-3 pb-6">
                    <div>
                        <span
                            className="p-2 text-xs flex truncate text-sky-600 cursor-pointer hover:underline"
                            onClick={() => onDownload("rows")}>
                            [download]
                        </span>
                    </div>
                    <div className="pb-4">
                        <SheetEditor
                            headers={lookup_table.headers}
                            rows={lookup_table_rows.map((row) => ({ uuid: row.uuid, row: row.row }))}
                            onAddRow={onAddRow}
                            onDeleteRow={onDeleteRow}
                            onUpdateRow={onUpdateRow} />
                    </div>
                </div>}

            {selected_tab === "upload_data" &&
                <div className="px-4 py-3 sm:px-0">
                    {lookup_table.active_version?.sheet !== undefined && lookup_table.active_version?.no_of_rows > 0 &&
                        <div className="pl-4 outer-div">
                            <span
                                className="p-2 text-xs flex truncate text-sky-600 cursor-pointer hover:underline"
                                onClick={() => onDownload("upload")}>
                                [download]
                            </span>
                            <Sheet data={lookup_table.active_version.sheet} />
                        </div>}
                    {lookup_table.active_version?.sheet !== undefined && lookup_table.active_version?.no_of_rows === 0 &&
                        <div className="pl-4 text-sm text-gray-900">No uploaded data</div>}
                    {lookup_table.active_version?.sheet === undefined &&
                        <div className="pl-4 text-sm text-gray-900">Sheet not available</div>}

                    <div className="pl-4 pt-4 text-sm font-medium leading-6 text-gray-900">Upload History</div>
                    <div className="p-4">
                        <table className="w-full divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-3 py-2 text-left text-sm font-semibold text-gray-900">Created</th>
                                    <th className="px-3 py-2 text-left text-sm font-semibold text-gray-900">Rows</th>
                                    <th className="w-32 px-3 py-2 text-center text-sm font-semibold text-gray-900">Status</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                                {reverse_versions.map((version, idx) => (
                                    <tr key={idx}>
                                        <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-600 align-top">{prettyDateTime(version.created_at)}</td>
                                        <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-600 align-top">{version.no_of_rows}</td>
                                        <td className="whitespace-nowrap px-3 py-2 text-sm align-top text-center">
                                            {version.uuid === lookup_table.active_version_uuid &&
                                                <Pill text="Active" type="info" disabled={is_activating} />}
                                            {version.uuid !== lookup_table.active_version_uuid &&
                                                <Pill text="Activate" type="default" disabled={is_activating} onClick={() => handleActivate(version.uuid)} />}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>}

            {selected_tab === "connected_endpoints" && has_endpoints && <div className="px-4 py-3 pb-6">
                <div className="py-3 flex flex-row items-center w-full">
                    <div className="flex-1 text-sm font-medium leading-6 text-gray-900">Connected Endpoints</div>
                    {is_org_admin && <Button icon={hi.PlusCircleIcon} text="Connect" href={`/endpoint-lookup-table-mapping/create/lookup_table/${lookup_table.uuid}`} />}
                </div>
                <EndpointToLookupTable
                    can_edit={is_org_admin}
                    source_type="lookup_table"
                    source_uuid={lookup_table.uuid}
                    endpoint_to_lookup_table_mappings={endpoint_to_lookup_table_mappings}
                    is_deleting={is_deleting}
                    deleteMapping={deleteMapping} />
            </div>}
        </div>
        <ConfirmModal open={show_confirm}
            title="Remove Lookup Table"
            message={["Are you sure you want to remove this lookup table?"]}
            confirm="Remove"
            onClose={onRemoveClose} />
    </div>;
}

type SortBy = "name_up" | "name_down" | "created_at_up" | "created_at_down" | "org_name_up" | "org_name_down";
type ItemType = "endpoint" | "lookup_table";
type IconType = "endpoint_email" | "endpoint_rest_api" | "lookup_table";

type IntegrationItem = {
    type: ItemType;
    icon: IconType;
    uuid: string;
    name: string;
    created_at: number;
    org_uuid: string;
}

export function Endpoints() {
    const navigate = useNavigate();

    const is_sidebar_large = useSelector(selectIsSidebarLarge);
    const memberships = useSelector(selectMemberships);

    const [endpoints, setEndpoints] = useState<t.IEndpointBase[] | undefined>(undefined);
    const [lookup_tables, setLookupTables] = useState<t.ILookupTableBase[] | undefined>(undefined);
    const [items, setItems] = useState<IntegrationItem[] | undefined>(undefined);
    const [sort_by, _setSortBy] = useState<SortBy>(localStorage.getItem("sort_endpoints_by") as SortBy ?? "name");

    useEffect(() => {
        BackendObj.extractions.listEndpoints({}).then(({ endpoints }) => {
            setEndpoints(endpoints);
        });
        Backend.getLookupTables().then((lookup_tables) => {
            setLookupTables(lookup_tables);
        });
    }, []);

    useEffect(() => {
        const new_items: IntegrationItem[] = [];
        if (endpoints !== undefined) {
            new_items.push(...endpoints.map((endpoint) => ({
                type: "endpoint" as ItemType,
                icon: (endpoint.type === "email" ? "endpoint_email" : "endpoint_rest_api") as IconType,
                uuid: endpoint.uuid,
                name: endpoint.name,
                created_at: endpoint.created_at,
                org_uuid: endpoint.org_uuid
            })));
        }
        if (lookup_tables !== undefined) {
            new_items.push(...lookup_tables.map((lookup_table) => ({
                type: "lookup_table" as ItemType,
                icon: "lookup_table" as IconType,
                uuid: lookup_table.uuid,
                name: lookup_table.name,
                created_at: lookup_table.created_at,
                org_uuid: lookup_table.org_uuid
            })));
        }
        const orgs = memberships.map((membership) => membership.org);
        new_items.sort((a, b) => {
            if (sort_by === "name_up") {
                return a.name.localeCompare(b.name);
            } else if (sort_by === "name_down") {
                return b.name.localeCompare(a.name);
            } else if (sort_by === "created_at_up") {
                return a.created_at - b.created_at;
            } else if (sort_by === "created_at_down") {
                return b.created_at - a.created_at;
            } else if (sort_by === "org_name_up") {
                const org_a = orgs.find(org => org.uuid === a.org_uuid)
                const org_b = orgs.find(org => org.uuid === b.org_uuid);
                return org_a?.name.localeCompare(org_b?.name ?? "") ?? 0;
            } else if (sort_by === "org_name_down") {
                const org_a = orgs.find(org => org.uuid === a.org_uuid)
                const org_b = orgs.find(org => org.uuid === b.org_uuid);
                return org_b?.name.localeCompare(org_a?.name ?? "") ?? 0;
            }
            // default is by name
            return a.name.localeCompare(b.name);
        });
        setItems(new_items);
    }, [endpoints, lookup_tables, sort_by, memberships]);

    const setSortBy = (sort_by: SortBy) => {
        localStorage.setItem("sort_endpoints_by", sort_by);
        _setSortBy(sort_by);
    }

    const selectItem = async (type: "endpoint" | "lookup_table", uuid: string) => {
        if (type === "endpoint") {
            navigate(`/endpoint/${uuid}`);
        } else {
            navigate(`/lookup_table/${uuid}`);
        }
    };

    if (endpoints === undefined || lookup_tables === undefined || items === undefined) {
        return <div className={classNames("hidden lg:fixed lg:right-0 lg:h-16 lg:flex lg:flex-row border-b-gray-200 border-b", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <LoadingSpinner />
        </div>;
    }

    if (endpoints.length === 0 && lookup_tables.length === 0) {
        return <EmptyList />;
    }
    const org_map = new Map<string, t.IOrganization>();
    for (const org of memberships.map((membership) => membership.org)) {
        org_map.set(org.uuid, org);
    }

    return <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
        <div className="h-16 w-full bg-white border-b border-b-gray-200">
            <div className="px-10 py-4 flex flex-row items-center max-w-5xl">
                <h2 className="text-xl font-semibold leading-7 text-gray-600 sm:truncate sm:text-2xl sm:tracking-tight">
                    Integrations
                </h2>
                <div className="flex-grow" />
                <Button highlight={true} icon={hi.PlusIcon} text="New" href="/endpoint/new" />
            </div>
        </div>
        <div className="max-w-5xl">
            <div className="p-10">
                <div className="rounded-lg shadow ring-1 ring-black ring-opacity-5">
                    <table className="w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                            <tr>
                                <th></th>
                                <th className="text-left p-3 text-sm cursor-pointer font-semibold text-gray-900"
                                    onClick={() => setSortBy(sort_by === "name_up" ? "name_down" : "name_up")}>
                                    <hi.ArrowsUpDownIcon
                                        className={classNames("h-4 w-4 inline  mr-1", sort_by === "name_up" || sort_by === "name_down" ? "text-gray-900" : "text-gray-400")}
                                    /> Name
                                </th>
                                <th className="w-32 text-left p-3 text-sm cursor-pointer font-semibold text-gray-900"
                                    onClick={() => setSortBy(sort_by === "created_at_down" ? "created_at_up" : "created_at_down")}>
                                    <hi.ArrowsUpDownIcon
                                        className={classNames("h-4 w-4 inline mr-1", sort_by === "created_at_up" || sort_by === "created_at_down" ? "text-gray-900" : "text-gray-400")}
                                    /> Created
                                </th>
                                <th className="w-40 text-right p-3 text-sm cursor-pointer font-semibold text-gray-900"
                                    onClick={() => setSortBy(sort_by === "org_name_up" ? "org_name_down" : "org_name_up")}>
                                    <hi.ArrowsUpDownIcon
                                        className={classNames("h-4 w-4 inline mr-1", sort_by === "org_name_up" || sort_by === "org_name_down" ? "text-gray-900" : "text-gray-400")}
                                    /> Organization
                                </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                            {items.map((item) => (
                                <tr key={item.uuid}
                                    className="hover:bg-sky-100 cursor-pointer hover:rounded-md"
                                    onClick={() => selectItem(item.type, item.uuid)}>
                                    <td className="p-3 w-14 text-gray-400 text-center align-middle">
                                        {item.icon === "endpoint_email" && <hi.EnvelopeIcon className="h-5 w-5 inline" />}
                                        {item.icon === "endpoint_rest_api" && <hi.Cog8ToothIcon className="h-5 w-5 inline" />}
                                        {item.icon === "lookup_table" && <hi.TableCellsIcon className="h-5 w-5 inline" />}
                                    </td>
                                    <td className="p-3 text-sm text-gray-900">
                                        <LongText text={item.name} line_limit={1} />
                                    </td>
                                    <td className="p-3 text-sm text-gray-400">
                                        {prettySmartDateTime(item.created_at)}
                                    </td>
                                    <td className="p-3 flex justify-end">
                                        {org_map.has(item.org_uuid) && <OrgPill
                                            name={org_map.get(item.org_uuid)?.name ?? ""}
                                            type={org_map.get(item.org_uuid)?.type ?? ORG_TYPES.personal} />}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>;
}