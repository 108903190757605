import { classNames } from "../lib/utils";

type TextboxProps = {
    placeholder?: string;
    disabled?: boolean;
    value: string;
    onChange?: (value: string) => void;
}

export function Textbox(props: TextboxProps) {
    const { placeholder, disabled, value, onChange } = props;

    return <div className={classNames(
        "flex w-full rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600",
        disabled ? "bg-gray-100 cursor-not-allowed text-gray-500" : "bg-white text-gray-900")}
    >
        <input
            type="text"
            className={classNames(
                "block flex-1 border-0 bg-transparent p-2  placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6",
                disabled ? "cursor-not-allowed text-gray-500" : "text-gray-900")}
            placeholder={placeholder}
            disabled={disabled}
            value={value}
            onChange={e => onChange && onChange(e.target.value)}
        />
    </div>;
}

export function InvisibleTextbox(props: TextboxProps) {
    const { placeholder, value, disabled, onChange } = props;

    return <div className="bg-white flex w-full focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600">
        <input
            type="text"
            className="block flex-1 border-0 bg-transparent p-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            placeholder={placeholder}
            disabled={disabled}
            value={value}
            onChange={e => onChange && onChange(e.target.value)}
        />
    </div>;
}